import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import classes from './CheckPointsList.module.scss';

export const points = [
  'Set up password',
  'Have a client download app',
  'Confirm that the clients has digital access to the Nutrition Playbook, Meal Prep & Recipe Guide',
  'Confirm that the client can access the Onboarding series',
  'Review in-app shortcuts with client',
  'Review in-app training videos, success factors & FAQ',
  'Help client log a breakfast, snack & dinner and save to Favorites (5 star)',
  'Talk about the importance of SMS & email educational campaigns',
];

const CheckPointsList = () => {
  return (
    <div className={classes.listBox}>
      {points.map((point) => (
        <div className={classes.listItem} key={point}>
          <CheckRoundedIcon fontSize="medium" color="success" />
          <span>{point}</span>
        </div>
      ))}
    </div>
  );
};

export { CheckPointsList };
