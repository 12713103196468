'use strict';

import { oldDateFormat } from "../../modules/shared/constants/apiDateFormat";

angular
    .module('app')
    .controller('CustomerGoalTabController', CustomerGoalTabController);

CustomerGoalTabController.$inject = ['$scope', '$routeParams', 'GoalService', 'ModalService', 'utilService'];

function CustomerGoalTabController($scope, $routeParams, GoalService, ModalService, utilService) {
    var vm = this;
    vm.spinner = {active: false};
    vm.currentUser = utilService.getLoggedInUserData();
    vm.userId = $routeParams.id;
    vm.scanData = {weight: 0, bodyFatPercent: 0};
    vm.goals = undefined;
    vm.showGoalForm = false;
    vm.targetDateIsOpen = false;
    vm.startDateIsOpen = false;
    vm.typesOfGoal = [
        {name: 'Lose Fat / Gain or Maintain Muscle'},
        {name: 'Lose Weight - Lose minimal muscle and Fat'},
        {name: 'Gain Muscle - Minimal Gain in Fat'},
        {name: 'Gain Weight - Muscle and Fat at 3:2 or better ratio of Muscle to Fat gain'}
    ];
    vm.selectedGoal = {data: {}};
    vm.startDate = undefined;
    vm.targetDate = undefined;
    vm.minDate = undefined;
    vm.maxDate = undefined;
    vm.currentMetrics = {}
    vm.firstScanDate = moment($scope.firstScan, oldDateFormat).toDate();

    vm.toggleGoalForm = toggleGoalForm;
    vm.getAllGoals = getAllGoals;
    vm.getOneGoal = getOneGoal;
    vm.saveGoal = saveGoal;
    vm.addGoal = addGoal;
    vm.updateGoal = updateGoal;
    vm.goToAddNewGoal = goToAddNewGoal;
    vm.editGoal = editGoal;
    vm.setDate = setDate;
    vm.closeDatePicker = closeDatePicker;
    vm.getClosestScanData = getClosestScanData;
    vm.showReachedGoalModal = showReachedGoalModal;
    vm.showNonReachedGoalModal = showNonReachedGoalModal;

    vm.getAllGoals();

    function toggleGoalForm(clearGoal) {
        if (clearGoal) {
            vm.selectedGoal = {
                data: {
                    startDate: moment(new Date()).format('MM.DD.YYYY')
                }
            };
            vm.startDate = moment(new Date());
        } else {
            vm.startDate = moment(vm.selectedGoal.data.startDate, oldDateFormat);
        }
        vm.targetDate = moment(vm.selectedGoal.data.targetDate, oldDateFormat);
        var start = new Date(moment(vm.startDate));
        var current = new Date();
        start.setDate(start.getDate() + 1);
        vm.minDate = start > current ? start : current;
        vm.maxDate = new Date(moment(vm.startDate).subtract(-100, 'years'));
        vm.showGoalForm = !vm.showGoalForm;
    }

    function getAllGoals() {
        vm.spinner.active = true;
        GoalService.getAllGoals(vm.userId).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.goals = res.data;
                vm.goals.past.some(function (item) {
                    if (item.notifyRequired && vm.currentUser.role !== 'CUSTOMER') {
                        if (item.progress < 1) {
                            vm.showNonReachedGoalModal(item, 'past');
                        } else {
                            vm.showReachedGoalModal(item);
                        }
                        return true;
                    }
                })
            }
        })
    }

    function getOneGoal(index, table) {
        if (!vm.goals[table][index].dashboard) {
            GoalService.getOneGoal(vm.userId, vm.goals[table][index].id).then(function (res) {
                if (res.status === 200) {
                    res.data.openDetails = true;
                    Object.assign(vm.goals[table][index], res.data);
                }
            })
        } else {
            vm.goals[table][index].openDetails = !vm.goals[table][index].openDetails;
        }
    }

    function saveGoal() {
        if (vm.selectedGoal.data.id) {
            vm.updateGoal();
        } else {
            vm.addGoal();
        }
    }

    function addGoal() {
        GoalService.addGoal(vm.userId, vm.selectedGoal.data).then(function (res) {
            if (res.status === 200) {
                vm.toggleGoalForm(true);
                vm.getAllGoals();
            }
        })
    }

    function updateGoal() {
        GoalService.updateGoal(vm.userId, vm.selectedGoal.data).then(function (res) {
            if (res.status === 200) {
                vm.toggleGoalForm(true);
                vm.getAllGoals();
            }
        })
    }

    function goToAddNewGoal() {
        vm.getClosestScanData();
        vm.toggleGoalForm(true);
    }

    function editGoal(data, table) {
        vm.selectedGoal.data = data;
        vm.selectedGoal.data.targetWeight = parseFloat(data.targetWeight.toFixed(1));
        // Convert to percentage only when it is not converted yet
        if (data.targetBodyFatPercent <= 1) {
            vm.selectedGoal.data.targetBodyFatPercent = parseFloat((data.targetBodyFatPercent * 100).toFixed(1));
        }
        vm.selectedGoal.table = table;
        vm.getClosestScanData();
        vm.toggleGoalForm();
    }

    function setDate(dateVariable, form) {
        if (dateVariable === 'startDate') {
            vm.getClosestScanData(form);
            var start = new Date(moment(vm.startDate));
            var current = new Date();
            start.setDate(start.getDate() + 1);
            vm.minDate = start > current ? start : current;
            vm.maxDate = new Date(moment(vm.startDate).subtract(-100, 'years'));
            if (vm.minDate.getTime() > new Date(moment(vm.targetDate)).getTime()) {
                vm.targetDate = vm.minDate;
                vm.selectedGoal.data.targetDate = moment(vm.minDate).format('MM.DD.YYYY')
            }
        }
        vm.selectedGoal.data[dateVariable] = moment(vm[dateVariable]).format('MM.DD.YYYY');
        vm.closeDatePicker(dateVariable);
    }

    function closeDatePicker(dateVariable) {
        $scope.$evalAsync(function () {
            vm[dateVariable + 'IsOpen'] = false;
        });
    }

    function getClosestScanData(form) {
        GoalService.getClosestScanData(vm.userId, moment(vm.startDate).format('MM-DD-YYYY')).then(function (res) {
            switch (res.status) {
                case 200:
                    vm.scanData = res.data;
                    vm.currentMetrics = {
                        weight: +vm.scanData.weight.toFixed(1),
                        percentBodyFat: +(vm.scanData.bodyFatPercent * 100).toFixed(1)
                    };
                    break;
                case 400:
                    if (form) {
                        form['startDate'].$setValidity('startDateError', false);
                    }
                    break;
            }
        })
    }

    function showReachedGoalModal(item) {
        ModalService.reachedGoal(vm);
        GoalService.updateNotified(vm.userId, item.id);
    }

    function showNonReachedGoalModal(item, key) {
        ModalService.nonReachedGoal(vm, item, key);
        GoalService.updateNotified(vm.userId, item.id);
    }
}

